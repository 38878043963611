import {
  ClockConfig,
  CustomHomepageGrid,
  HeaderWorldClock,
  HomePageCompanyLogo,
  HomePageRandomJoke,
  HomePageStarredEntities,
  HomePageToolkit,
  HomePageTopVisited,
  HomePageRecentlyVisited,
  WelcomeTitle,
} from '@backstage/plugin-home';
import { Content, Header, Page } from '@backstage/core-components';
import { HomePageSearchBar } from '@backstage/plugin-search';
import { HomePageCalendar } from '@backstage/plugin-gcalendar';
import HomeIcon from '@material-ui/icons/Home';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AddAlert from '@mui/icons-material/AddAlert';
import Github from '@mui/icons-material/GitHub';
import { HomePagePagerDutyCard } from '@backstage/plugin-pagerduty';

const clockConfigs: ClockConfig[] = [
  {
    label: 'ARG',
    timeZone: 'America/Buenos_Aires',
  },
  {
    label: 'UTC',
    timeZone: 'UTC',
  },
  {
    label: 'POL',
    timeZone: 'Europe/Poland',
  },
  {
    label: 'TYO',
    timeZone: 'Asia/Tokyo',
  },
];

const timeFormat: Intl.DateTimeFormatOptions = {
  hour: '2-digit',
  minute: '2-digit',
  hour12: true,
};

const defaultConfig = [
  {
    component: 'CompanyLogo',
    x: 0,
    y: 0,
    width: 12,
    height: 1,
    movable: false,
    resizable: false,
    deletable: false,
  },
  {
    component: 'WelcomeTitle',
    x: 0,
    y: 1,
    width: 12,
    height: 2,
  },
  {
    component: 'HomePageSearchBar',
    x: 0,
    y: 2,
    width: 12,
    height: 2,
  },
  {
    component: 'HomePageStarredEntities',
    x: 0,
    y: 10,
    width: 12,
    height: 2,
  },
  {
    component: 'HomePageToolkit',
    x: 0,
    y: 12,
    width: 12,
    height: 2,
  },
  {
    component: 'HomePageTopVisited',
    x: 0,
    y: 14,
    width: 12,
    height: 2,
  },
  {
    component: 'HomePageRecentlyVisited',
    x: 0,
    y: 16,
    width: 12,
    height: 2,
  }
];

export const homePage = (
  <Page themeId="home">
    <Header title={<WelcomeTitle language={['English']} />} pageTitleOverride="Home">
      <HeaderWorldClock
        clockConfigs={clockConfigs}
        customTimeFormat={timeFormat}
      />
    </Header>
    <Content>
      <CustomHomepageGrid config={defaultConfig}>
        <HomePageSearchBar />
        <HomePageRandomJoke />
        <HomePageCalendar />
        <HomePagePagerDutyCard name="Rota" />
        <HomePageStarredEntities />
        <HomePageCompanyLogo />
        <WelcomeTitle />
        <HomePageToolkit
          tools={[
            {
              url: 'https://backstage.io',
              label: 'Backstage Homepage',
              icon: <HomeIcon />,
            },
            {
              url: 'https://console.cloud.google.com/welcome?hl=en-AU&project=prod-ts&supportedpurview=project',
              label: 'Google Cloud Console',
              icon: <CloudUploadIcon />,
            },
            {
              url: 'https://travelshift.pagerduty.com/incidents',
              label: 'PagerDuty',
              icon: <AddAlert />,
            },
            {
              url: 'https://github.com/GuideToIceland',
              label: 'Github',
              icon: <Github />
            }
          ]}
        />
        <HomePageTopVisited />
        <HomePageRecentlyVisited />
      </CustomHomepageGrid>
    </Content>
  </Page>
);